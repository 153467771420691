<template>
  <Combobox as="div" v-model="selected" by="id">
    <div class="relative">
      <div class="inline-flex divide-x divide-header-topbar-button rounded-md shadow-sm">
        <div class="inline-flex divide-x divide-header-topbar-button rounded-md shadow-sm">
          <ComboboxInput
            :style="`min-width: ${minWidth}px;`"
            class="inline-flex items-center rounded-l-md border border-transparent bg-header-topbar-button py-1 pl-3 pr-4 text-header-topbar-text shadow-sm text-sm font-medium focus:ring-0 focus:ring-offset-0 focus:border-transparent"
            :displayValue="(investor) => investor.title"
            @change="query = $event.target.value"
            :disabled="(isKeyNumbersLoading || investors.length < 2)"
          />
          <ComboboxButton
            class="inline-flex items-center rounded-r-md bg-header-topbar-button p-1 text-sm font-medium text-header-topbar-text"
          >
            <IconLoader v-if="isKeyNumbersLoading && investors.length > 1" class="h-4 w-4 animate-spin" />
            <Icon
              v-if="!isKeyNumbersLoading && investors.length > 1"
              name="heroicons:chevron-down-20-solid"
              aria-hidden="true"
              class="h-5 w-5 text-white"
            />
          </ComboboxButton>
        </div>
      </div>

      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @before-enter="scrollToSelectedInvestor()"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          v-if="investors.length > 1"
          class="absolute z-10 mt-2 w-72 sm:w-[30rem] overflow-x-auto origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div v-bind="containerProps" class="max-h-96">
            <div
              v-if="filteredInvestors.length === 0 && query !== ''"
              class="relative cursor-default select-none py-2 px-4 text-primary-500"
            >
              {{ t('words.no_search_results') }}
            </div>
            <div v-bind="wrapperProps">
              <ComboboxOption
                v-for="{ index, data } in list"
                as="template"
                :key="index"
                :value="data"
                v-slot="{ selected, active }"
              >
                <li :class="[active ? 'text-white bg-header-topbar-main' : 'text-gray-900', 'cursor-pointer select-none p-4 text-sm']">
                  <div class="flex flex-col">
                    <div class="flex justify-between">
                      <div class="flex gap-2">
                        <p :class="selected ? 'font-semibold' : 'font-normal'">{{ data.title }}</p>
                        <p 
                          v-if="displayCoInvestorTag(data)"
                          class="inline-flex items-center rounded-md bg-gray-100 px-2 text-xs font-medium text-gray-600"
                        >
                          {{ t(`menu.ownershipTypes.${data.coInvestorType.toLowerCase()}`) }} ({{ data.coInvestorRate }}%)
                        </p>
                      </div>
                      <span v-if="selected" :class="active ? 'text-white' : 'text-primary-500'">
                        <Icon name="heroicons:check-20-solid" class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </div>
                    <table :class="[active ? 'text-header-topbar-text' : 'text-gray-500', 'mt-2 text-center text-xs']">
                      <thead>
                        <tr>
                          <th
                            v-for="keyNumber in data.keyNumbers"
                            :key="keyNumber.code"
                          >
                            {{ t(`menu.key_numbers.${keyNumber.code}`) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            v-for="keyNumber in data.keyNumbers"
                            :key="keyNumber.code"
                          >
                            {{ keyNumber.value.display }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ComboboxOption>
            </div>
          </div>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';
import { useCompanyStore } from '~/store/company';
import { Cookie } from '~/utils/cookies';
import { useI18n } from 'vue-i18n';
import { useVirtualList } from '@vueuse/core';
import { CoInvestorType } from '~/generated/operations-airfund';

const { t } = useI18n()
const companyStore = useCompanyStore()
const cookieSelectedInvestorId = useCookie(Cookie.SELECTED_INVESTOR_ID)

const investors = computed(() => {
  return companyStore.user.investors.map((investor) => {
    return {
      id: investor.id,
      current: investor.id === companyStore.selectedInvestorId,
      title: investor.name,
      keyNumbers: investor.keyNumbers,
      coInvestorType: investor.coInvestorType,
      coInvestorRate: investor.coInvestorRate
    }
  })
})

const isKeyNumbersLoading = computed(() => {
  return investors.value.every((investor) => investor.keyNumbers.length === 0)
})

const selected = ref(investors.value.find(option => option.current))

const minWidth = computed(() => {
  const width = (selected.value.title.length * 8) + 10
  if (width < 150) {
    return 150
  } else if (width > 425) {
    return 425
  } else {
    return width
  }
})

const query = ref('')

const displayCoInvestorTag = ((data) => {
  return data?.coInvestorType === CoInvestorType.Usufructuary || data?.coInvestorType === CoInvestorType.BareOwner
})

const filteredInvestors = computed(() => {
  if (!query.value) {
    return investors.value
  } else {
    return investors.value.filter((investor) =>
      investor.title
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    )
  }
})

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
  filteredInvestors,
  {
    itemHeight: 96,
    overscan: 10
  }
)

function scrollToSelectedInvestor() {
  const index = filteredInvestors.value.findIndex((investor) => investor.current)
  scrollTo(index)
}

watch(selected, (selectedInv) => {
  cookieSelectedInvestorId.value = selectedInv!.id
  companyStore.selectedInvestorId = selectedInv!.id
  window.location.reload()
})

watch(query, (value) => {
  if (value) {
    scrollTo(0)
  } else {
    scrollToSelectedInvestor()
  }
})

</script>
